@include placeholder() {
  color: $dark-gray;
};


.form-control {
    height: unset;
    background-color: $white-color;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-base;
    color: $font-color;
    line-height: normal;
    font-size: $font-size-base;
    @include transition-input-focus-color();
    @include box-shadow(none);



    &:focus{
        border: 1px solid $dark-gray;
        @include box-shadow(none);
        outline: 0 !important;
        color: $default-color;

        & + .input-group-append .input-group-text,
        & ~ .input-group-append .input-group-text,
        & + .input-group-prepend .input-group-text,
        & ~ .input-group-prepend .input-group-text{
            border: 1px solid #ccc;
            border-left: none;
            background-color: $transparent-bg;
        }
    }

    .has-success &,
    .has-error &,
    .has-success &:focus,
    .has-error &:focus{
        @include box-shadow(none);
    }

    .has-success &{
        border: 1px solid $table-line-color;
        color: $font-color;

        &.form-control-success{
            padding-right: 2.5em !important;
        }
    }
    .has-success &:focus{
        border: 1px solid $success-color;
        color: $success-color;
    }
    .has-danger &{

      border: 1px solid $danger-color;
        color: $danger-color;

        &.form-control-danger{
            padding-right: 2.5em !important;
        }
    }
    .has-danger &:focus{
        background-color: $white-color;
        border: 1px solid $danger-color;
    }

    & + .form-control-feedback{
        border-radius: $border-radius-large;
        font-size: $font-size-base;
        margin-top: -7px;
        position: absolute;
        right: 10px;
        top: 50%;
        vertical-align: middle;
    }

    .open &{
        border-radius: $border-radius-large $border-radius-large 0 0;
        border-bottom-color: transparent;
    }

    & + .input-group-append .input-group-text,
    & + .input-group-prepend .input-group-text{
        background-color: $white-bg;
    }
}


@include form-control-lg-padding($padding-large-vertical, $padding-input-horizontal);
@include input-base-padding($padding-input-vertical, $padding-input-horizontal);

.input-group {
  &.has-success {
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        border: 1px solid $table-line-color;
        color: $font-color;
        background-color: $white-color;
        border-right: none;
      }
    }
  }
}

.form-group.no-border,
.input-group.no-border{
    .form-control,
    .form-control + .input-group-prepend .input-group-text,
    .form-control + .input-group-append .input-group-text{
        background-color: $opacity-gray-3;
        border: medium none;
        &:focus,
        &:active,
        &:active{
            border: medium none;
            background-color: $opacity-gray-5;
        }
    }

    .form-control{
        &:focus{
            & + .input-group-prepend .input-group-text,
            & + .input-group-append .input-group-text{
                background-color: $opacity-gray-5;
            }
        }
    }

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
        background-color: $opacity-gray-3;
        border: none;
    }
}

.has-error{
    .form-control-feedback, .control-label{
        color: $danger-color;
    }
}
.has-success{
    .form-control-feedback, .control-label{
        color: $success-color;
    }
}

.input-group.has-danger {
  .input-group-prepend {
    border-radius: $border-radius-base;
    .input-group-text {
      border: 1px solid $danger-color;
      border-right: none;
    }
  }
  .error {
    display: block;
    width: 100%;
    color: $danger-color;
    margin-top: 3px;
  }
}

.input-group.has-success {
  .input-group-prepend {
    border-radius: $border-radius-base;
    .input-group-text {
      // border: 1px solid $success-color;
      border-right: none;
    }
  }
}


.input-group-focus{
  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text{
    background-color: $white-bg;
    border-color: $dark-gray;
  }

  &.no-border{
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
      background-color: $opacity-gray-5;
    }
  }

  &.has-danger {
    .input-group-append,
    .input-group-prepend {
      .input-group-text {
        background-color: $danger-input-bg;
      }
    }
  }

  &.has-success {
    .input-group-append,
    .input-group-prepend {
      .input-group-text {
        background-color: $success-input-bg;
        border: 1px solid $success-color;
        border-right: none;
      }
    }
  }
}

.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
    background-color: transparent;
    border: 1px solid $light-gray;
    color: $default-color;
    border-top-right-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;

    & i{
      opacity: .5;
    }

    @include transition-input-focus-color();

    .has-danger &{
      background-color: $danger-input-bg;
    }
    .has-success &{
      background-color: $success-input-bg;
    }
    .has-danger.input-group-focus &{
      background-color: $white-color;
      color: $danger-color;
    }
    .has-success.input-group-focus &{
      background-color: $white-color;
      color: $success-color;
    }
    .has-danger .form-control:focus + &{
      color: $danger-color;
    }
    .has-success .form-control:focus + &{
      color: $success-color;
    }

    & + .form-control,
    & ~ .form-control{
        @include input-size($padding-base-vertical - 1, $padding-base-horizontal);
        padding-left: 18px;
    }

    i{
        width: 17px;
    }
}

.input-group-append,
.input-group-prepend{
  margin: 0;
}


.input-group-append .input-group-text{
  border-left: none;
}
.input-group-prepend .input-group-text{
  border-right: none;
}

.input-group,
.form-group{
    margin-bottom: 10px;
    position: relative;

    .form-control-static{
        margin-top: 9px;
    }
    &.has-danger {
      .error {
        color: $danger-color;
      }
    }
}
.input-group[disabled]{
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
        background-color: $light-gray;
    }
}

.input-group .form-control:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child){
    border-radius: $border-radius-base;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 none;
}

.input-group .form-control:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border-left: 0 none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: $light-gray;
    color: $default-color;
    cursor: not-allowed;
}

.input-group-btn .btn{
    border-width: $border-thin;
    padding: $padding-btn-vertical  $padding-base-horizontal;
}
.input-group-btn .btn-default:not(.btn-fill){
    border-color: $medium-gray;
}

.input-group-btn:last-child > .btn{
    margin-left: 0;
}
textarea.form-control{
    max-width: 100%;
    max-height: 80px;
    padding: 10px 10px 0 0;
    resize: none;
    border: none;
    border: 1px solid $light-gray;
    border-radius: $border-radius-base;
    line-height: 2;
}

.has-success,
.has-danger{

    &.form-group .form-control,
    &.form-group.no-border .form-control{
        padding-right: $padding-input-horizontal + 21;
    }
}

.form.form-newsletter .form-group{
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
}

.input-group .input-group-btn{
    padding: 0 12px;
}

// Input files - hide actual input - requires specific markup in the sample.
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-text{
    font-size: $font-size-small;
}

.form-control-lg{
    padding: 0;
    font-size: inherit;
    line-height: 0;
    border-radius: 0;
}

.form-horizontal{
    .col-form-label,
    .label-on-right{
        padding: 10px 5px 0 15px;
        text-align: right;
        max-width: 180px;
    }

    .checkbox-radios{
        margin-bottom: 15px;

        .form-check:first-child{
           margin-top: 8px;
       }
    }

    .label-on-right{
        text-align: left;
        padding: 10px 15px 0 5px;
    }

    .form-check-inline{
        margin-top: 6px;
    }
}
