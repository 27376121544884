.white-button {
  background-color: white !important;
  color: $nutrideck-blue !important;
  border:1px solid $nutrideck-blue !important;
}

.white-button:hover {
  background-color: #efefef !important;
  color: $nutrideck-blue !important;
}
