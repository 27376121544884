.ancora-container {
  .btn-group-choose-score .btn {
    padding-left: 12px;
    padding-right: 12px;
  }
  .btn-group-choose-score .btn.active.disabled {
    background-color: #51bcda !important;
    color: white !important;
    opacity: 1;
  }
}

