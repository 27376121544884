.setup-page {
  background-color: #f4f3ef;

  .logo {
    padding: 10px 0;
    text-align: center;

    img {
      width: 200px;
    }
  }

  .content {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }
}