@import '../paper-dashboard/variables';
.bsidebar .wrapper {
  display: block;
}

.bsidebar#sidebar {
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  height: 100vh;
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  /* top layer */
  z-index: 9999;
  background: #fefefe;
  color: #fff;
  transition: all 0.3s;
}

.bsidebar#sidebar.active {
  display: block;
}

.bsidebar .overlay {
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}

/* display .overlay when it has the .active class */
.bsidebar .overlay.active {
  display: block;
  opacity: 1;
}

.bsidebar #dismiss {
  width: 35px;
  height: 35px;
  position: absolute;
  /* top right corner of the sidebar */
  top: 10px;
  right: 10px;
}

.bsidebar#sidebar .sidebar-header {
  padding: 1px;
  background-color: #408000;
}

@media screen and (max-width: 413px){
  .bsidebar#sidebar {
    width: 100vh;
    min-width: 100vh;
    max-width: 100vh;
    height: 100vh;
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    /* top layer */
    z-index: 9999;
    background: #fefefe;
    color: #fff;
    transition: all 0.3s;
  }

}
