.chat-page {
  background-color: #f4f3ef;

  .logo {
    padding: 10px 0;
    text-align: center;

    img {
      width: 140px;
    }
  }

  .content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
}