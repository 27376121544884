.card-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 137.5%;
  /* identical to box height, or 33px */

  display: flex;
  align-items: center;

  color: #000000;
}

.label-exam {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;

  color: #000000;
}
.label-exam-value {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #000000;
}

.form-exames-readonly {
  background-color: transparent !important;
  border: 0 !important;
}

