.status-program {
  .timeline {
    list-style-type: none;
    display: flex;
    padding-inline-start: 0px !important;
  }

  .li {
  }

  .timestamp {
    margin-bottom: 20px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
  }

  .timestamp-last {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
  }

  .status {
    display: flex;
    border-top: 2px solid #D6DCE0;
    position: relative;
    transition: all 200ms ease-in;
  }

  .status:before {
    content: "";
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    top: -8.5px;
    transition: all 200ms ease-in;
  }

  .li.complete .status {
    border-top: 2px solid #6FCF97;
  }

  .li.complete .status:before {
    background-color: #6FCF97;
    border: none;
    transition: all 200ms ease-in;
  }

  .li.done .status:before {
    background-color: #6FCF97;
    border: none;
    transition: all 200ms ease-in;
  }

  .li.waiting .status:before {
    background-color: #F2994A;
    border: none;
    transition: all 200ms ease-in;
  }

  .li.complete .status h4 {
    color: #6FCF97;
  }

}
