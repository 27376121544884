.appointment-list-calendar {
  .rbc-allday-cell {
    display: none;
  }

  .rbc-day-slot .rbc-event {
    min-height: 40px;
  }

  .btn-cancel {
    margin: 0;
  }

  // &.day-view {
  //   .btn-cancel {
  //     height: 1.5rem;
  //     min-width: 1.5rem;
  //     width: 1.5rem;
  //   }
  // }

  &.week-view {
    .rbc-event-content {
      padding: 7px 0;
      text-overflow: ellipsis;
    }

  //   .btn-cancel {
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     height: 1.5rem;
  //     min-width: 1.5rem;
  //     width: 1.5rem;
  //   }
  }

  span.cancelled {
    text-decoration: line-through;
  }
}
