.home-specialist {
  .no-item {
    background: #F2F2F2;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 5px;
    height: 25vh;
    display: flex;
    align-content: space-between;
    align-items: center;
    justify-content: center;
    text-align: center;
    span {
      display: flex;

      letter-spacing: 0.15px;

      color: #4F4F4F;
    }
    .smile-face {
      transform: rotate(90deg);
    }
  }
}
