.plan-meal {

  &.card {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
  }

  &.no-food {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }

  &.color {

    &.proteina {
      color: #F51063;
    }

    &.carboidrato {
      color: #8A31A9;
    }

    &.lipideo {
      color: #FF9141;
    }



    &.bg-proteina {
      background-color: #F51063;
      padding: 5px;
      height: 30px;
      color: #FFF;
    }
    &.bg-carboidrato {
      background-color: #8A31A9;
      padding: 5px;
      height: 30px;
      color: #FFF;
    }
    &.bg-lipideo {
      background-color: #FF9141;
      padding: 5px;
      height: 30px;
      color: #FFF;
    }
  }

  &.add-custom-meal {
    height: 73px;
    width: 100%;
    border: 2px solid #007AFF;
    box-sizing: border-box;
    border-radius: 5px;
  }

  &.label-custom-food {
    color: #9A9A9A;
    font-size: 11px;
  }

  &.table-alternatives {
    background: #F9F9F9;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
  th {
      font-weight: 400;
    }
  }

}

.modal-alimentos {
  th {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    align-items: center;
    letter-spacing: 0.25px;
  }

  td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
}




