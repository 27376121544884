:root {
  --input-padding-x: .75rem;
  --input-padding-y: .75rem;
}

.nutrideck {

  &.label {
    color: #9A9A9A;
    font-size: 11px;
    margin:0;
    padding:0;
  }

  &.h5-subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
  }

  &.p-about {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #999999;
  }
  &.p-about-size {
    font-size: 20px;
    color: #000;
  }


}
.card-patient {
  height: 150px !important;
}
.card-subject {
  height: 30px !important;
  align-items: center;
}


.bagde-mensagens-nao-respondidas {

  /* Orange / 6 */

  background: #FEEDDC;
  padding: 5px;
  border-radius: 100px;
  color: #FB8832;
}
.bagde-com-lembretes {

  /* Orange / 6 */

  background: #F8D8FF;
  padding: 5px;
  border-radius: 100px;
  color: #9013FE;
}
.bagde-com-agendamento {

  /* Orange / 6 */

  background: #FFE3E3;
  padding: 5px;
  border-radius: 100px;
  color: #FF5756;
}

.login-nutrideck {
  .nav-link span{
    color: #828282 !important;
    font-weight: bold;
    font-size: 16px
  }
  .button {
    padding: 5px;
    background-color: #40811E !important;
    color: #FFF !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    line-height: 34px;
    font-size: 16px;
    font-family: Nunito;
    font-weight: 700;
  }
  .nav-link span.white{
    color: #FFF !important;
    font-weight: bold;
    font-size: 16px
  }
}

.input-group-prepend {
  border-color: gray !important;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input:not(.error), .form-label-group .rdt  input,
.form-label-group > label:not(.error),.form-label-group .rdt label, {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label:not(.error),.form-label-group .rdt label:not(.error) {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label:not(.error) {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
.form-label-group .rdt input:not(:placeholder-shown) label:not(.error)  {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.text-divider{margin: 5px 0; line-height: 0; text-align: center;}
.text-divider span{background-color: white; padding-left: 1em; padding-right:1em;padding-bottom: 0px}
.text-divider:before{ content: " "; display: block; border-top: 1px solid #e3e3e3; border-bottom: 1px solid #f7f7f7;}


textarea[name='conteudo'] {
  border: 1px solid !important;
  border-radius: 5px;
  max-height: 300px;
}
