.card-appointment {

  &.card {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
  }

  &.title {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    margin-bottom: 0;
    border: 0px !important;
  }

  &.content {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    /* or 143% */

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    /* Dark */

    color: #333333;
  }


}

