.file-upload {

  .file-info {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #9C9C9C;
  }
}
