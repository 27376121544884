.specialist-card {

  .card {
    background-color: #F8F8F8 !important;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .title-about {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    padding-left: 10px;
    padding-bottom: 20px;

    /* Dark */

    color: #333333;
  }
  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    color: #999999;
  }
  .text-content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    /* Dark */

    color: #333333;
  }
}

.home-member-content {

  .card {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
