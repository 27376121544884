.challenge {

  &.nutrideck-card-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 137.5%;
    align-items: center;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 80px;
  }

  &.size-modal {
    width: 450px;
  }

  &.title-card-front {
    text-align: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &.title-card-content {
    font-size: 14px;
  }
}
