@import "../paper-dashboard/_variables.scss";

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

.chatbox {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  padding: 5px;
  width: 109vw;
  overflow: hidden;
  max-height: 80vh;
  height: 80vh;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  padding: 10px;
  border-top: var(--border);
  background-color: #F0F0F0;
  width: 100%;
}

.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
  background-color: #F0F0F0;

}

.msger-input {
  background: #ddd;
}

.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}

.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.chat-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  z-index: 99;
  overflow: hidden;
}

.boot-drop {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  z-index: 9999;
  height: 100vh;
  box-shadow: 0 2px 8px
}
.chat-header {

  background-color: $primary-color;
  color: white;
  padding: 10px;
  .fa {
    font-size: 20px;
  }

}
.chat-header #dismiss {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 1px;
}
@media screen and (max-width: 413px){
  .boot-drop {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    z-index: 9999;
    height: 100%;
    background-color: white;
    box-shadow: 0 2px 8px
  }

  .chat-panel {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-width: 100vh;
    max-width: 100vh;
    z-index: 99;
    overflow: hidden;
  }
  .chatbox {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 5px;
    width: 109vw;
    overflow: hidden;
    max-height: 66vh;
    height: 66vh;
    border: var(--border);
    border-radius: 5px;
    background: var(--msger-bg);
    box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  }

  input[type='text'],
  input[type='number'],
  textarea {
    font-size: 16px !important;
  }

}
